import { isNil, sortBy } from "lodash";

import { entityType } from "../components/utils/entityType";
import { documentType } from "components/utils/constants";
import { cisSearchType } from "components/utils/constants";

export const availableResources = [
    {
        resourceName: "2fa",
        resourceUrl: `${process.env.REACT_APP_AUTHENTICATION_API_BASE_URL}users/{userName}/twofactor`,
        listName: null,
    },
    {
        resourceName: "ssoToken",
        resourceUrl: `${process.env.REACT_APP_AUTHENTICATION_API_BASE_URL}ssotokens`,
        listName: null,
    },
    {
        resourceName: "ssoGroups",
        resourceUrl: `${process.env.REACT_APP_AUTHENTICATION_API_BASE_URL}ssogroups`,
        listName: null,
    },
    {
        resourceName: "password",
        resourceUrl: `${process.env.REACT_APP_AUTHENTICATION_API_BASE_URL}password`,
        listName: null,
    },
    {
        resourceName: "passwordGroups",
        resourceUrl: `${process.env.REACT_APP_AUTHENTICATION_API_BASE_URL}password/groups`,
        listName: null,
    },
    {
        resourceName: "userPassword",
        resourceUrl: `${process.env.REACT_APP_AUTHENTICATION_API_BASE_URL}users/password`,
        listName: null,
    },
    {
        resourceName: "usersAuthCode",
        resourceUrl: `${process.env.REACT_APP_AUTHENTICATION_API_BASE_URL}users/authcode`,
        listName: null,
    },
    {
        resourceName: "passwordSsrs",
        resourceUrl: `${process.env.REACT_APP_AUTHENTICATION_API_BASE_URL}password/ssrs`,
        listName: null,
    },
    {
        resourceName: "calculations",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}calculations`,
        listName: null,
    },
    {
        resourceName: "calculationAttributes",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}calculations/attributes`,
        listName: "calculationAttributesList",
    },
    {
        resourceName: "calculationEventAttributes",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}calculations/eventattributes`,
        listName: "calculationEventAttributesList",
    },
    {
        resourceName: "calculationLookups",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}calculations/lookups`,
        listName: "calculationLookupsList",
    },
    {
        resourceName: "calculationFunctions",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}calculations/functions`,
        listName: "calculationFunctionsList",
    },
    {
        resourceName: "calculationUtilityProductAttributes",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}calculations/utilityproductattributes`,
        listName: "productAttributesList",
    },
    {
        resourceName: "calculationUtilityEventAttributes",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}calculations/utilityeventattributes`,
        listName: "eventAttributesList",
    },
    {
        resourceName: "calculationUtilityFormItems",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}calculations/utilityformitems`,
        listName: "formItemsList",
    },
    {
        resourceName: "calculationProgramProductAttributes",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}calculations/programproductattributes`,
        listName: "productAttributesList",
    },
    {
        resourceName: "calculationProgramEventAttributes",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}calculations/programeventattributes`,
        listName: "eventAttributesList",
    },
    {
        resourceName: "calculationProgramFormItems",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}calculations/programformitems`,
        listName: "formItemsList",
    },
    {
        resourceName: "calculationProgramFormFields",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}calculations/programformfields`,
        listName: "formFieldsList",
    },
    {
        resourceName: "calculationTestInputs",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}calculations/{calculationNumber}/testinputs`,
        listName: null,
    },
    {
        resourceName: "newCalculationTestInputs",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}calculations/testinputs`,
        listName: null,
    },
    {
        resourceName: "calculationTest",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}calculations/{calculationNumber}/test`,
        listName: null,
    },
    {
        resourceName: "newCalculationTest",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}calculations/test`,
        listName: null,
    },
    {
        resourceName: "calculationValidate",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}calculations/validate`,
        listName: null,
    },
    {
        resourceName: "calculationLookuptables",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}calculations/lookuptables`,
        listName: null,
    },
    {
        resourceName: "calculationLookupTableAllItems",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}calculations/lookuptables/{lookupTableNumber}/items`,
        listName: null,
    },
    {
        resourceName: "calculationLookupTableItems",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}calculations/lookuptableitems`,
        listName: null,
    },
    {
        resourceName: "calculationAssignments",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}calculations/assignments`,
        listName: null,
    },
    {
        resourceName: "calculationEventAssignments",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}calculations/eventassignments`,
        listName: null,
    },
    {
        resourceName: "programCalculationsAssignments",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}calculations/forms/assignments`,
        listName: null,
    },
    {
        resourceName: "users",
        resourceUrl: `${process.env.REACT_APP_USERS_API_BASE_URL}users`,
        listName: null,
    },
    {
        resourceName: "usersQrCode",
        resourceUrl: `${process.env.REACT_APP_USERS_API_BASE_URL}users/{userNumber}/qrcode`,
        listName: null,
    },
    {
        resourceName: "userPasswordReset",
        resourceUrl: `${process.env.REACT_APP_USERS_API_BASE_URL}users/{userNumber}/password`,
        listName: null,
    },
    {
        resourceName: "userProfile",
        resourceUrl: `${process.env.REACT_APP_USERS_API_BASE_URL}profile`,
        listName: null,
    },
    {
        resourceName: "userSettings",
        resourceUrl: `${process.env.REACT_APP_USERS_API_BASE_URL}settings`,
        listName: null,
    },
    {
        resourceName: "userThemes",
        resourceUrl: `${process.env.REACT_APP_USERS_API_BASE_URL}themes`,
        listName: null,
    },
    {
        resourceName: "userFavorites",
        resourceUrl: `${process.env.REACT_APP_USERS_API_BASE_URL}users/favorites`,
        listName: null,
    },
    {
        resourceName: "userReporting",
        resourceUrl: `${process.env.REACT_APP_USERS_API_BASE_URL}reporting`,
        listName: null,
    },
    {
        resourceName: "userSupplementData",
        resourceUrl: `${process.env.REACT_APP_USERS_API_BASE_URL}users/supplement/{userNumber}`,
        listName: null,
    },
    {
        resourceName: "clients",
        resourceUrl: `${process.env.REACT_APP_AUTHORIZATION_API_BASE_URL}clients`,
        listName: null,
    },
    {
        resourceName: "utilityClients",
        resourceUrl: `${process.env.REACT_APP_AUTHORIZATION_API_BASE_URL}utilities/{utilityNumber}/clients`,
        listName: null,
    },
    {
        resourceName: "clientPrograms",
        resourceUrl: `${process.env.REACT_APP_AUTHORIZATION_API_BASE_URL}client/{clientNumber}/programs`,
        listName: null,
    },
    {
        resourceName: "clientRoles",
        resourceUrl: `${process.env.REACT_APP_AUTHORIZATION_API_BASE_URL}client/{clientNumber}/clientroles`,
        listName: null,
    },
    {
        resourceName: "clientUsers",
        resourceUrl: `${process.env.REACT_APP_AUTHORIZATION_API_BASE_URL}client/{clientNumber}/users`,
        listName: null,
    },
    {
        resourceName: "clientRoleUsers",
        resourceUrl: `${process.env.REACT_APP_AUTHORIZATION_API_BASE_URL}clientroles/{clientRoleId}/users`,
        listName: null,
    },
    {
        resourceName: "userRoles",
        resourceUrl: `${process.env.REACT_APP_AUTHORIZATION_API_BASE_URL}users/{userNumber}/roles`,
        listName: null,
    },
    {
        resourceName: "userRights",
        resourceUrl: `${process.env.REACT_APP_AUTHORIZATION_API_BASE_URL}users/rights`,
        listName: null,
    },
    {
        resourceName: "userRolesClone",
        resourceUrl: `${process.env.REACT_APP_AUTHORIZATION_API_BASE_URL}users/{userNumber}/roles/clone`,
        listName: null,
    },
    {
        resourceName: "clientRoleRights",
        resourceUrl: `${process.env.REACT_APP_AUTHORIZATION_API_BASE_URL}clientroles/{clientRoleId}/rights`,
        listName: null,
    },
    {
        resourceName: "dashboards",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}dashboards`,
        listName: "dashboardList",
    },
    {
        resourceName: "dashboardsInitialize",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}dashboards/initialize/{dashboardType}`,
        listName: null,
    },
    {
        resourceName: "dashboardsAvailable",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}dashboards/availabledashboards`,
        listName: null,
    },
    {
        resourceName: "publicDashboardsAvailable",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}dashboards/availabledashboards/public`,
        listName: null,
    },
    {
        resourceName: "document",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}document`,
        listName: null,
    },
    {
        resourceName: "documentStatus",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}document/{documentNumber}/status`,
        listName: null,
    },
    {
        resourceName: "fasttags",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}fasttags`,
        listName: null,
    },
    {
        resourceName: "fasttagsV2",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}fasttagsv2`,
        listName: null,
    },
    {
        resourceName: "files",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}files`,
        listName: null,
    },
    {
        resourceName: "fileList",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}filelist`,
        listName: null,
    },
    {
        resourceName: "fileTags",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}files/{fileId}/tags`,
        listName: null,
    },
    {
        resourceName: "fileFolder",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}files/{fileId}/folder`,
        listName: null,
    },
    {
        resourceName: "fileSecurity",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}files/{fileId}/security`,
        listName: null,
    },
    {
        resourceName: "fileAuthorizationGroup",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}files/{fileId}/authorizationgroup`,
        listName: null,
    },
    {
        resourceName: "fileUploadReqs",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}files/supported/{entityTypeId}`,
        listName: null,
    },
    {
        resourceName: "bulkApplicationUpload",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}bulkappfiles`,
        listName: null,
    },
    {
        resourceName: "bulkApplicationStatusUpload",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}bulkappstatusfiles`,
        listName: null,
    },
    {
        resourceName: "fileAuthorizationList",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}fileauthorizationlist`,
        listName: null,
    },
    {
        //TODO: change endpoint to something like: '/applications/{appId}/filefolders' or move appId in query params.  MM: I have added endpoint with appid as query param.
        resourceName: "fileFolders",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}filefolders/{appId}`,
        listName: null,
    },
    {
        resourceName: "forms",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}forms`,
        listName: null,
    },
    {
        resourceName: "programForm",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}forms`,
        listName: null,
    },
    {
        resourceName: "programFormConfiguration",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}forms/{pageNumber}/configuration`,
        listName: null,
    },
    {
        resourceName: "programForms",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/forms`,
        listName: null,
    },
    {
        resourceName: "programFormGenProcs",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/forms/genprocs`,
        listName: null,
    },
    {
        resourceName: "programFormFriendlyNames",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/formsfriendlynames`,
        listName: null,
    },
    {
        resourceName: "programFormPages",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/forms/{formNumber}/pages`,
        listName: null,
    },
    {
        resourceName: "programFormPageOrder",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/forms/{formNumber}/pages/order`,
        listName: null,
    },
    {
        resourceName: "programFormPageFields",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/forms/{formNumber}/pages/{pageNumber}/fields`,
        listName: null,
    },
    {
        resourceName: "workflow",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}workflow`,
        listName: null,
    },
    {
        resourceName: "workflowSteps",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}workflow/{wfNumber}/steps`,
        listName: null,
    },
    {
        resourceName: "programs",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs`,
        listName: "programList",
    },
    {
        resourceName: "programsApplicationTransfer",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/transfer`,
        listName: "programList",
    },
    {
        resourceName: "programAttributes",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/attributeTypes/{attributeType}`,
        listName: null,
    },
    {
        resourceName: "programCopy",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/copy`,
        listName: null,
    },
    {
        resourceName: "programGoals",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/goals`,
        listName: null,
    },
    {
        resourceName: "programGoalsDelete",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/goals/{goalNumber}/delete`,
        listName: null,
    },
    {
        resourceName: "programGroups",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/groups`,
        listName: null,
    },
    {
        resourceName: "programContent",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/content`,
        listName: null,
    },
    {
        resourceName: "programContentItems",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/content/{contentNumber}/items`,
        listName: null,
    },
    {
        resourceName: "programDocuments",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/documents`,
        listName: null,
    },
    {
        resourceName: "programDocumentRevision",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/documents/{documentNumber}/revisions`,
        listName: null,
    },
    {
        resourceName: "programCatalogs",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/catalogs`,
        listName: null,
    },
    {
        resourceName: "programCatalogCategories",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/catalogs/categories`,
        listName: null,
    },
    {
        resourceName: "programCatalogCategoriesv2",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/catalogs/categoriesv2`,
        listName: null,
    },
    {
        resourceName: "programCatalogCategoryIcons",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/catalogs/categories/icons`,
        listName: null,
    },
    {
        resourceName: "programCatalogAttributes",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/catalogs/attributes`,
        listName: null,
    },
    {
        resourceName: "programCatalogApprovedMeasures",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/catalogs/approvedmeasures`,
        listName: null,
    },
    {
        resourceName: "programCatalogCalculations",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/catalogs/calculations`,
        listName: null,
    },
    {
        resourceName: "programCatalogBudgetLines",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/catalogs/budgetlinesv2`,
        listName: null,
    },
    {
        resourceName: "programEvents",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/events`,
        listName: null,
    },
    {
        resourceName: "programEventCategories",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/events/categories`,
        listName: null,
    },
    {
        resourceName: "programEventAttributes",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/events/attributes`,
        listName: null,
    },
    {
        resourceName: "programEventCalculations",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/events/calculations`,
        listName: null,
    },
    {
        resourceName: "programWorkflowStatus",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/workflow/status`,
        listName: null,
    },
    {
        resourceName: "programWorkflowAssignments",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/workflow/assignments`,
        listName: null,
    },
    {
        resourceName: "programWorkflowStates",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/workflow/states`,
        listName: null,
    },
    {
        resourceName: "programWorkflowStepTypes",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/workflow/steps/types`,
        listName: null,
    },
    {
        resourceName: "programWorkflowStepAttributeTypes",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/workflow/steps/attributes/types`,
        listName: null,
    },
    {
        resourceName: "generalAttributes",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}generalAttributes`,
        listName: null,
    },
    {
        resourceName: "budgetAttributeTypes",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}budgetAttributeTypes`,
        listName: null,
    },
    {
        resourceName: "productAttributes",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}productAttributes`,
        listName: null,
    },
    {
        resourceName: "ftpSchedules",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}ftp/schedules`,
        listName: null,
    },
    {
        resourceName: "ftpConfigs",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}ftp`,
        listName: null,
    },
    {
        resourceName: "eventAttributes",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}eventAttributes`,
        listName: null,
    },
    {
        resourceName: "reference",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}reference`,
        listName: null,
    },
    {
        resourceName: "entityReference",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}reference/entities/{entityNumber}`,
        listName: null,
    },
    {
        resourceName: "referenceFilter",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}reference/filter`,
        listName: null,
    },
    {
        resourceName: "states",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}states`,
        listName: null,
    },
    {
        resourceName: "documentQueuePrograms",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}documentqueue/programs`,
        listName: null,
    },
    {
        resourceName: "documentQueueProgramEntries",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}documentqueue/programs/{programNumber}`,
        listName: null,
    },
    {
        resourceName: "documentQueueBatches",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}documentqueue/batches/utilityNumber/{utilityNumber}/archived/{archived}`,
        listName: null,
    },
    {
        resourceName: "documentQueueBatchEntries",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}documentqueue/batches/{batchNumber}`,
        listName: null,
    },
    {
        resourceName: "documentQueueBatch",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}documentqueue/batch`,
        listName: null,
    },
    {
        resourceName: "documentQueueBatchRevert",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}documentqueue/batch/revert`,
        listName: null,
    },
    {
        resourceName: "documentQueueBatchArchive",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}documentqueue/batch/{documentBatchNumber}/archive`,
        listName: null,
    },
    {
        resourceName: "utilityCustomerSearch",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/customers/search`,
        listName: null,
    },
    {
        resourceName: "utilityCustomerSearchV2",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/customers/searchv2`,
        listName: null,
    },
    {
        resourceName: "utilityGroups",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/groups`,
        listName: "groupList",
    },
    {
        resourceName: "utilityGroupUsers",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/groups/{groupNumber}/users`,
        listName: "groupUserList",
    },
    {
        resourceName: "utilityGroupPrograms",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/groups/{groupNumber}/programs`,
        listName: "groupProgramList",
    },
    {
        resourceName: "utilityGroupsByAssignedEntity",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/groups/assignments/{groupType}/{entityType}/{entityNumber}`,
        listName: "groupProgramList",
    },
    {
        resourceName: "utilities",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities`,
        listName: null,
    },
    {
        resourceName: "cisUtilities",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/cis`,
        listName: null,
    },
    {
        resourceName: "utilitiesRebateApprovals",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/rebateapprovals`,
        listName: "rebateApprovalList",
    },
    {
        resourceName: "utilitiesRebateRejections",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/rebaterejections`,
        listName: null,
    },
    {
        resourceName: "utilitiesRebateApprovalsPrograms",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/rebateapprovalsprograms`,
        listName: "programList",
    },
    {
        resourceName: "utilitiesRebateApprovalsStates",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/rebateapprovalsstates`,
        listName: "programList",
    },
    {
        resourceName: "utilitiesRebateApprovalsDates",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/rebateapprovalsdates`,
        listName: "dateList",
    },
    {
        resourceName: "utilitiesRebateApprovalsReceivedDates",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/rebateapprovalsreceiveddates`,
        listName: "receivedDateList",
    },
    {
        resourceName: "utilitiesContracts",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts`,
        listName: "contractList",
    },
    {
        resourceName: "utilitiesContractsBudgetLookups",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts/{contractNumber}/budgetlookups`,
        listName: "budgetLookupList",
    },
    {
        resourceName: "utilitiesContractsBudgetLines",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts/{contractNumber}/budgetlines`,
        listName: "budgetLineList",
    },
    {
        resourceName: "utilitiesContractsAttributes",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts/{contractNumber}/attributes`,
        listName: "contractAttributeList",
    },
    {
        resourceName: "utilitiesContractsAttributeTypes",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts/{contractNumber}/attributetypes`,
        listName: "contractAttributeTypeList",
    },
    {
        resourceName: "utilitiesContractsBudgetLineCategories",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts/{contractNumber}/budgetlinecategories`,
        listName: "budgetLineCategoryList",
    },
    {
        resourceName: "utilitiesContractsBudgetLinesAmounts",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts/{contractNumber}/budgetlines/{budgetLineNumber}/amounts`,
        listName: "budgetLineAmountList",
    },
    {
        resourceName: "utilitiesContractsBudgetLinesWorkflowStatus",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts/{contractNumber}/budgetlines/{budgetLineNumber}/workflow/status`,
        listName: "budgetLineAttributeList",
    },
    {
        resourceName: "utilitiesContractsBudgetLinesAttributes",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts/{contractNumber}/budgetlines/{budgetLineNumber}/attributes`,
        listName: "budgetLineAttributeList",
    },
    {
        resourceName: "utilitiesContractsBudgetLinesAttributeTypes",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts/{contractNumber}/budgetlineattributetypes`,
        listName: "budgetLineAttributeTypeList",
    },
    {
        resourceName: "utilitiesContractsBudgetLinesPrograms",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts/{contractNumber}/budgetlines/{budgetLineNumber}/programs`,
        listName: "budgetLineProgramList",
    },
    {
        resourceName: "utilitiesContractsBudgetLinesWorkflowDetails",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts/{contractNumber}/budgetlines/{budgetLineNumber}/workflow/complete`,
        listName: null,
    },
    {
        resourceName: "utilitiesContractsInvoiceDocuments",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts/{contractNumber}/documents`,
        listName: "invoiceDocumentList",
    },
    {
        resourceName: "invoiceDocument",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/contracts/documents`,
        listName: null,
    },
    {
        resourceName: "utilitiesContractsInvoiceDocumentsClone",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts/{contractNumber}/documents/{documentNumber}/clone`,
        listName: null,
    },
    {
        resourceName: "utilitiesContractsInvoiceDocumentsStatus",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts/{contractNumber}/documents/{documentNumber}/status`,
        listName: null,
    },
    {
        resourceName: "utilitiesContractsInvoiceDocumentsVendors",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts/{contractNumber}/documents/vendors`,
        listName: null,
    },
    {
        resourceName: "utilitiesContractsInvoiceDocumentsAttributes",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts/{contractNumber}/documents/{documentNumber}/attributes`,
        listName: "invoiceDocumentAttributeList",
    },
    {
        resourceName: "utilitiesContractsInvoiceDocumentsFlags",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts/{contractNumber}/documents/{documentNumber}/flags`,
        listName: null,
    },
    {
        resourceName: "utilitiesContractsInvoiceDocumentsInvoices",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts/{contractNumber}/documents/{documentNumber}/invoices`,
        listName: "invoiceList",
    },
    {
        resourceName: "utilitiesInvoiceWorkflow",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}invoices/{invoiceMasterNumber}/workflowV2`,
        listName: null,
    },
    {
        resourceName: "utilitiesInvoiceWorkflowHistory",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}invoices/{invoiceMasterNumber}/workflow/history`,
        listName: "workflowHistory",
    },
    {
        resourceName: "utilitiesInvoiceWorkflowTasks",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}invoices/{invoiceMasterNumber}/tasks`,
        listName: null,
    },
    {
        resourceName: "utilitiesInvoiceWorkflowTaskOwnership",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}invoices/{invoiceMasterNumber}/taskownership`,
        listName: null,
    },
    {
        resourceName: "utilitiesContractsBudgetLinesInvoices",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts/{contractNumber}/budgetlines/{budgetLineNumber}/invoices`,
        listName: "invoiceList",
    },
    {
        resourceName: "utilitiesContractsBudgetCostsInvoices",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts/{contractNumber}/budgetcost/{budgetCostId}/invoices`,
        listName: "invoiceList",
    },
    {
        resourceName: "utilitiesContractsInvoiceDocumentsNotes",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts/{contractNumber}/documents/{documentNumber}/notes`,
        listName: "invoiceDocumentList",
    },
    {
        resourceName: "utilitiesContractsWorkflowDetails",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts/{contractNumber}/workflow/complete`,
        listName: null,
    },
    {
        resourceName: "utilitiesContractsWorkflowStatuses",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts/{contractNumber}/workflow/status`,
        listName: null,
    },
    {
        resourceName: "utilitiesContractsWorkflowStep",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts/{contractNumber}/workflow/{wfNumber}/step`,
        listName: null,
    },
    {
        resourceName: "utilitiesContractsWorkflowSteps",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts/{contractNumber}/workflow/{wfNumber}/steps`,
        listName: null,
    },
    {
        resourceName: "utilitiesWorkflowStates",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/workflow/states`,
        listName: null,
    },
    {
        resourceName: "utilitiesWorkflowStepTypes",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/workflow/steps/types`,
        listName: null,
    },
    {
        resourceName: "utilitiesWorkflowStepAttributeTypes",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/workflow/steps/attributes/types`,
        listName: null,
    },
    {
        resourceName: "utilitiesWorkflowAssignments",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/contracts/{contractNumber}/assignments`,
        listName: null,
    },
    {
        resourceName: "utilityWithAttribute",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/attributes/{attribute}`,
        listName: null,
    },
    {
        resourceName: "utilityProgramsWithAttribute",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/attributes/{attribute}/programs`,
        listName: null,
    },
    {
        resourceName: "applicationDetails",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/details`,
        listName: null,
    },
    {
        resourceName: "applicationFormPageDetails",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{applicationNumber}/details/page`,
        listName: null,
    },
    {
        resourceName: "applicationTags",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/tags`,
        listName: null,
    },

    {
        resourceName: "fileUploadTags",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/filetags`,
        listName: null,
    },
    {
        resourceName: "applicationFiles",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/files`,
        listName: null,
    },
    {
        resourceName: "applicationFlags",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/flags`,
        listName: null,
    },
    {
        resourceName: "applicationForms",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}forms/{appId}`,
        listName: null,
    },
    {
        resourceName: "programApplicationForms",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}forms/programs`,
        listName: null,
    },
    {
        resourceName: "applications",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications`,
        listName: null,
    },
    {
        resourceName: "applicationProjectName",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/projectname`,
        listName: null,
    },
    {
        resourceName: "applicationItems",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/items`,
        listName: null,
    },
    {
        resourceName: "applicationFormGenProc",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{applicationNumber}/programs/{programNumber}/genprocs/{procId}`,
        listName: null,
    },
    {
        resourceName: "applicationContractors",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{applicationNumber}/contractorlist`,
        listName: null,
    },
    {
        resourceName: "applicationNotes",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/notes`,
        listName: null,
    },
    {
        resourceName: "applicationContacts",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/contacts`,
        listName: null,
    },
    {
        resourceName: "applicationContactsCIS",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{applicationNumber}/contacts/{contactNumber}/cis`,
        listName: null,
    },
    {
        resourceName: "applicationContactsAssign",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{applicationNumber}/contacts/{contactNumber}/assign/{assignType}`,
        listName: null,
    },
    {
        resourceName: "applicationContactsUnassign",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{applicationNumber}/contacts/unassign/{assignType}`,
        listName: null,
    },
    {
        resourceName: "applicationRebates",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/rebates`,
        listName: null,
    },
    {
        resourceName: "applicationRebatesWidget",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/rebateswidget`,
        listName: null,
    },
    {
        resourceName: "applicationRebatesAvailable",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/rebates/available`,
        listName: null,
    },
    {
        resourceName: "applicationRebateseEquipmentRelations",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/rebates/equipmentrelations`,
        listName: null,
    },
    {
        resourceName: "applicationRebateStatus",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/rebates/{rebateNumber}/status`,
        listName: null,
    },
    {
        resourceName: "applicationRebateDate",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/rebates/{rebateNumber}/date`,
        listName: null,
    },
    {
        resourceName: "applicationRebateReject",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/rebates/{rebateNumber}/reject`,
        listName: null,
    },
    {
        resourceName: "applicationRebateResetDate",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/rebates/{rebateNumber}/resetdate`,
        listName: null,
    },
    {
        resourceName: "applicationEquipmentWidget",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/equipmentwidget`,
        listName: null,
    },
    {
        resourceName: "applicationEventsWidget",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/events/widget`,
        listName: null,
    },
    {
        resourceName: "applicationLocation",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/location`,
        listName: null,
    },
    {
        resourceName: "applicationStatus",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{applicationNumber}/status`,
        listName: null,
    },
    {
        resourceName: "applicationContractorsByProgram",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}programs/{programNumber}/contractors`,
        listName: null,
    },
    {
        resourceName: "applicationContractorsByApplication",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{applicationNumber}/contractors`,
        listName: null,
    },
    {
        resourceName: "powerBI",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}powerbi/{reportID}`,
        listName: null,
    },
    {
        resourceName: "zillow",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}zillow`,
        listName: null,
    },
    {
        resourceName: "applicationActivity",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/activity`,
        listName: null,
    },
    {
        resourceName: "applicationWorkflow",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/workflowV2`,
        listName: "workflowList",
    },
    {
        resourceName: "applicationWorkflowHistory",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/workflow/history`,
        listName: "workflowHistory",
    },
    {
        resourceName: "applicationWorkflowTasks",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/tasks`,
        listName: null,
    },
    {
        resourceName: "applicationWorkflowTaskOwnership",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/taskownership`,
        listName: null,
    },
    {
        resourceName: "applicationFormDetails",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/details/page`,
        listName: null,
    },
    {
        resourceName: "programWorkflowDetails",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}workflow/complete/{programNumber}`,
        listName: null,
    },
    {
        resourceName: "applicationWorkflowStatus",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}applications/{applicationNumber}/workflow/status`,
        listName: null,
    },
    {
        resourceName: "programWorkflowStatusApplicationTransfer",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/workflow/status/transfer`,
        listName: null,
    },
    {
        resourceName: "applicationWorkflowStatusTransfer",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}applications/{applicationNumber}/workflow/status/transfer`,
        listName: null,
    },
    {
        resourceName: "programAvailableDocuments",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/documents/available`,
        listName: null,
    },
    {
        resourceName: "programHostname",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/hostnamev2`,
        listname: null,
    },
    {
        resourceName: "applicationAvailableDocuments",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}applications/{appId}/documents/available`,
        listName: null,
    },
    {
        resourceName: "impersonatePortal",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/customer/impersonate`,
        listName: null,
    },
    {
        resourceName: "similarPremises",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/contacts`,
        listName: null,
    },
    {
        resourceName: "event",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/events`,
        listName: null,
    },
    {
        resourceName: "applicationEventForCreate",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/programevents`,
        listName: null,
    },
    {
        resourceName: "eventCategories",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/events/categories`,
        listName: null,
    },
    {
        resourceName: "eventCatalogSummary",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/events/usageSummary`,
        listName: null,
    },
    {
        resourceName: "eventsSearch",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/event/search`,
        listName: null,
    },
    {
        resourceName: "qc",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/qc`,
        listName: null,
    },
    {
        resourceName: "qcStatus",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/qc/{qcNumber}/status/{status}`,
        listName: null,
    },
    {
        resourceName: "qcDescription",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/qc/{qcNumber}/description`,
        listName: null,
    },
    {
        resourceName: "qcNote",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/qc/{qcNumber}/notes`,
        listName: null,
    },
    {
        resourceName: "updateQCNote",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/notes`,
        listName: null,
    },
    {
        resourceName: "qcWidget",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/qcwidget`,
        listName: null,
    },
    {
        resourceName: "qcDelete",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/qc/{qcNumber}`,
        listName: null,
    },
    {
        resourceName: "eventsInCategory",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/events/category`,
        listName: null,
    },
    {
        resourceName: "equipment",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/equipment`,
        listName: null,
    },
    {
        resourceName: "equipmentExport",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/equipment/export`,
        listName: null,
    },
    {
        resourceName: "equipmentCategories",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/equipment/categories`,
        listName: null,
    },
    {
        resourceName: "equipmentCategoryCatalogs",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}equipment/categories/{categoryNumber}/catalogs`,
        listName: null,
    },
    {
        resourceName: "approvedEquipment",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/equipment/approvedmeasure`,
        listName: null,
    },
    {
        resourceName: "equipmentCatalog",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/catalog`,
        listName: null,
    },
    {
        resourceName: "equipmentCatalogSummary",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/catalog/usageSummary`,
        listName: null,
    },
    {
        resourceName: "equipmentCategoryDetails",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/equipment/category`,
        listName: null,
    },
    {
        resourceName: "equipmentSearch",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/equipment/search`,
        listName: null,
    },
    {
        resourceName: "approvedEquipmentSearch",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/equipment/approvedmeasure/search`,
        listName: null,
    },
    {
        resourceName: "approvedEquipmentGrid",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/equipment/approvedmeasure/grid`,
        listName: null,
    },
    {
        resourceName: "approvedEquipmentDetails",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}equipment/industryMeasureNumber/{industryMeasureNumber}/rowId`,
        listName: null,
    },
    {
        resourceName: "applicationToolsTransfer",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/tools/transfer/{programNumber}`,
        listName: null,
    },
    {
        resourceName: "applicationToolsTransferCustomer",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/tools/transfercustomer/{customerNumber}`,
        listNameL: null,
    },
    {
        resourceName: "applicationToolsAssignments",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/tools/assignments`,
        listName: null,
    },
    {
        resourceName: "applicationToolsAssignmentsList",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/tools/assignmentlist`,
        listName: null,
    },
    {
        resourceName: "applicationToolsRelations",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/tools/relations`,
        listName: null,
    },
    {
        resourceName: "applicationToolsCopy",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/tools/copy`,
        listName: null,
    },
    {
        resourceName: "applicationToolsBookmarks",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/tools/bookmark`,
        listName: null,
    },
    {
        resourceName: "applicationToolsQuickClicks",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/tools/quickclicks/{flagType}`,
        listName: null,
    },
    {
        resourceName: "applicationToolsExclusions",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/tools/exclusions`,
        listName: null,
    },
    {
        resourceName: "applicationToolsSearch",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/tools/search/{searchString}`,
        listName: null,
    },
    {
        resourceName: "applicationCalculationStatus",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/calculations/status`,
        listName: null,
    },
    {
        resourceName: "forceExecuteCalculations",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{appId}/calculations/execute`,
        listName: null,
    },
    {
        resourceName: "applicationScanQueue",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}scanapplications`,
        listName: null,
    },
    {
        resourceName: "applicationScanQueueAvailable",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}scanapplications/available`,
        listName: null,
    },
    {
        resourceName: "applicationScanQueueReport",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}scanapplications/report`,
        listName: null,
    },
    {
        resourceName: "workcenter",
        resourceUrl: `${process.env.REACT_APP_WORKCENTER_API_BASE_URL}workcenter`,
        listName: null,
    },
    {
        resourceName: "workcenterUserList",
        resourceUrl: `${process.env.REACT_APP_WORKCENTER_API_BASE_URL}workcenter/userlist`,
        listName: null,
    },
    {
        resourceName: "workcenterDefaultDataSource",
        resourceUrl: `${process.env.REACT_APP_WORKCENTER_API_BASE_URL}workcenter/{datasourceNumber}/default`,
        listName: null,
    },
    {
        resourceName: "workcenterUserAssignments",
        resourceUrl: `${process.env.REACT_APP_WORKCENTER_API_BASE_URL}workcenter/{datasourceNumber}/userassignments`,
        listName: null,
    },
    {
        resourceName: "workcenterItemUsers",
        resourceUrl: `${process.env.REACT_APP_WORKCENTER_API_BASE_URL}workcenter/items/users`,
        listName: null,
    },
    {
        resourceName: "cisCustomers",
        resourceUrl: `${process.env.REACT_APP_CIS_API_BASE_URL}customers`,
        listName: null,
    },
    {
        resourceName: "cisCustomerDetails",
        resourceUrl: `${process.env.REACT_APP_CIS_API_BASE_URL}customerdetails`,
        listName: null,
    },
    {
        resourceName: "cisCustomerDetailsAdvanced",
        resourceUrl: `${process.env.REACT_APP_CIS_API_BASE_URL}customerinfo`,
        listName: null,
    },
    {
        resourceName: "cisSearchCriteria",
        resourceUrl: `${process.env.REACT_APP_CIS_API_BASE_URL}utilities/{utilityNumber}/searchcriteria`,
        listName: null,
    },
    {
        resourceName: "cisEligibilityVerification",
        resourceUrl: `${process.env.REACT_APP_CIS_API_BASE_URL}swc/accounts/addresses`,
        listName: null,
    },
    {
        resourceName: "customers",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/customers`,
        listName: null,
    },
    {
        resourceName: "customersByAccount",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/customersByAccount`,
        listName: null,
    },
    {
        resourceName: "customerContacts",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/customers/{customerNumber}/contacts`,
        listName: null,
    },
    {
        resourceName: "customerPassword",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/customers/{customerNumber}/password`,
        listName: null,
    },
    {
        resourceName: "utilityPortalTemplates",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/portalTemplates`,
        listName: null,
    },
    {
        resourceName: "programPortalTemplates",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/portals/utilitytemplates`,
        listName: null,
    },
    {
        resourceName: "utilityPortalTemplateRevision",
        resourceUrl: `${process.env.REACT_APP_UTILITY_API_BASE_URL}utilities/{utilityNumber}/portaltemplates/{portalTemplateNumber}/revisions/{portalTemplateRevisionNumber}`,
        listName: null,
    },
    {
        resourceName: "programPortalTemplateRevision",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/portals/configuration/revisions/{portalTemplateRevisionNumber}`,
        listName: null,
    },
    {
        resourceName: "utilityPortalTemplateFiles",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}utilities/{utilityNumber}/portaltemplates/files`,
        listName: null,
    },

    {
        resourceName: "portalConfiguration",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/portals/configuration`,
        listName: null,
    },
    {
        resourceName: "search",
        resourceUrl: `${process.env.REACT_APP_SEARCH_API_BASE_URL}search`,
        listName: null,
    },
    {
        resourceName: "searchOptions",
        resourceUrl: `${process.env.REACT_APP_SEARCH_API_BASE_URL}search/options`,
        listName: null,
    },
    {
        resourceName: "searchHistory",
        resourceUrl: `${process.env.REACT_APP_SEARCH_API_BASE_URL}search/history`,
        listName: null,
    },
    {
        resourceName: "grid",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}grid`,
        listName: null,
    },
    {
        resourceName: "gridColumns",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}grid/{dataGridId}/columns`,
        listName: null,
    },
    {
        resourceName: "gridConstruct",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}grid/{dataGridId}/construct`,
        listName: null,
    },
    {
        resourceName: "scanGroups",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}scangroups`,
        listName: null,
    },
    {
        resourceName: "scanGroupProgramOrder",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}scangroups/programorder`,
        listName: null,
    },
    {
        resourceName: "scanGroupUsers",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}scangroups/{groupNumber}/users`,
        listName: null,
    },
    {
        resourceName: "scanGroupPrograms",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}scangroups/{groupNumber}/programs`,
        listName: null,
    },
    {
        resourceName: "groups",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}groups`,
        listName: "groupList",
    },
    {
        resourceName: "groupsByUser",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}groups/users`,
        listName: null,
    },
    {
        resourceName: "groupUsers",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}groups/{groupNumber}/users`,
        listName: "groupUserList",
    },
    {
        resourceName: "groupPrograms",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}groups/{groupNumber}/programs`,
        listName: "groupProgramList",
    },
    {
        resourceName: "groupUtilities",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}groups/{groupNumber}/utilities`,
        listName: "groupUtilityList",
    },
    {
        resourceName: "generatePdf",
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}pdf`,
        listName: null,
    },
    {
        resourceName: "analyticsDashboardAccessKey",
        resourceUrl: `${process.env.REACT_APP_ANALYTICS_API_BASE_URL}dashboard/accesskey`,
        listName: null,
    },
    {
        resourceName: "analyticsEvents",
        resourceUrl: `${process.env.REACT_APP_ANALYTICS_API_BASE_URL}events`,
        listName: null,
    },
    {
        resourceName: "analyticsUserPaths",
        resourceUrl: `${process.env.REACT_APP_ANALYTICS_API_BASE_URL}userpathsV2`,
        listName: null,
    },
    {
        resourceName: "analyticsAppInsights",
        resourceUrl: `${process.env.REACT_APP_ANALYTICS_API_BASE_URL}portalappinsights`,
        listName: null,
    },
    {
        resourceName: "analyticsUserInsights",
        resourceUrl: `${process.env.REACT_APP_ANALYTICS_API_BASE_URL}portaluserinsights`,
        listName: null,
    },
    {
        resourceName: "analyticsCampaigns",
        resourceUrl: `${process.env.REACT_APP_ANALYTICS_API_BASE_URL}campaigns`,
        listName: null,
    },
    {
        resourceName: "portalStatistics",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/portals/statistics`,
        listName: null,
    },
    {
        resourceName: "portalAccounts",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/portals/accounts`,
        listName: null,
    },
    {
        resourceName: "portalCustomerCreations",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/portals/customercreations/statistics`,
        listName: null,
    },
    {
        resourceName: "contractorAppZipCodeCount",
        resourceUrl: `${process.env.REACT_APP_APPLICATIONS_API_BASE_URL}applications/{applicationNumber}/contractors/{contactNumber}`,
        listName: null,
    },
    {
        resourceName: "portalZipCodeCount",
        resourceUrl: `${process.env.REACT_APP_PROGRAM_API_BASE_URL}programs/{programNumber}/portals/zipcodecount`,
        listName: null,
    },
    {
        resourceName: "allReleaseNotes",
        resourceUrl: `${process.env.REACT_APP_SERVICES_API_BASE_URL}allreleasenotes`,
        listName: null,
    },
    {
        resourceName: "currentReleaseNumber",
        resourceUrl: `${process.env.REACT_APP_SERVICES_API_BASE_URL}currentreleasenumber`,
        listName: null,
    },
    {
        resourceName: "externalApps",
        resourceUrl: `${process.env.REACT_APP_USERS_API_BASE_URL}externalapplications`,
        listName: null,
    },
    {
        resourceName: "applicationTokens",
        resourceUrl: `${process.env.REACT_APP_AUTHENTICATION_API_BASE_URL}applicationtokensv2`,
        listName: null,
    },
];

export const getResourceUrl = (resourceName, pathParams = {}) => {
    let url = null;

    const resource = availableResources.filter((r) => r.resourceName === resourceName)[0];

    if (resource) {
        if (Object.keys(pathParams).length) {
            url = Object.keys(pathParams).reduce(
                (path, paramKey) => path.replace(`{${paramKey}}`, pathParams[paramKey]),
                resource.resourceUrl
            );
        } else {
            url = resource.resourceUrl;
        }
    }

    return url;
};

export const getResourceKey = ({ resourceId, key } = {}) => {
    const value = key ? key : resourceId;

    return isNil(value) ? value : String(value);
};

export const getResourceCallbackKey = ({ resourceName, resourceId, key } = {}) => {
    return `${resourceName}-${getResourceKey({ resourceId, key })}`;
};

export const getUtiltyResourceParams = ({ utilityNumber }) => {
    return {
        resourceName: "utilities",
        resourceId: utilityNumber,
    };
};

export const getProgramResourceParams = ({ programNumber }) => {
    return {
        resourceName: "programs",
        resourceId: programNumber,
    };
};

export const getProgramsResourceParams = ({ utilityNumber }) => {
    return {
        resourceName: "programs",
        key: utilityNumber,
        query: {
            utilityNumber,
        },
        transform: (data) => {
            return (data && data.programList) || [];
        },
    };
};

export const getProgramsApplicationTransferResourceParams = ({ utilityNumber }) => {
    return {
        resourceName: "programsApplicationTransfer",
        key: utilityNumber,
        query: {
            utilityNumber,
        },
        transform: (data) => {
            return (data && data.programList) || [];
        },
    };
};

export const getApplicationResourceParams = ({ applicationNumber }) => {
    return {
        resourceName: "applicationDetails",
        key: applicationNumber,
        path: {
            appId: applicationNumber,
        },
    };
};

export const getApplicationTagsResourceParams = ({ applicationNumber }) => {
    return {
        resourceName: "applicationTags",
        key: applicationNumber,
        path: {
            appId: applicationNumber,
        },
    };
};

export const getApplicationFlagsResourceParams = ({ applicationNumber }) => {
    return {
        resourceName: "applicationFlags",
        key: applicationNumber,
        path: {
            appId: applicationNumber,
        },
    };
};

export const getEquipmentInCategoryResourceParams = ({ applicationNumber, categoryNumber }) => {
    return {
        resourceName: "equipmentCategoryCatalogs",
        key: categoryNumber,
        path: {
            categoryNumber,
        },
        query: {
            appId: applicationNumber,
        },
    };
};

export const getEventCategoriesResourceParams = ({ applicationNumber, categoryNumber }) => {
    return {
        resourceName: "eventCategories",
        key: applicationNumber,
        path: {
            appId: applicationNumber,
        },
        query: {
            categoryNumber,
        },
    };
};

export const getEventsInCategoryResourceParams = ({ applicationNumber, categoryNumber }) => {
    return {
        resourceName: "eventsInCategory",
        resourceId: categoryNumber,
        path: {
            appId: applicationNumber,
        },
    };
};

export const getApplicationWorkflowHistoryResourceParams = ({ applicationNumber }) => {
    return {
        resourceName: "applicationWorkflowHistory",
        key: applicationNumber,
        path: {
            appId: applicationNumber,
        },
    };
};

export const getUtilitiesInvoiceWorkflowResourceParams = ({ documentNumber }) => {
    return {
        resourceName: "utilitiesInvoiceWorkflow",
        key: documentNumber,
        path: {
            invoiceMasterNumber: documentNumber,
        },
    };
};

export const getUtilitiesInvoiceWorkflowHistoryResourceParams = ({ documentNumber }) => {
    return {
        resourceName: "utilitiesInvoiceWorkflowHistory",
        key: documentNumber,
        path: {
            invoiceMasterNumber: documentNumber,
        },
    };
};

export const getUtilitiesWorkflowAssignmentsResourceParams = ({ utilityNumber, contractNumber }) => {
    return {
        resourceName: "utilitiesWorkflowAssignments",
        key: contractNumber,
        path: {
            utilityNumber,
            contractNumber,
        },
    };
};

export const getProgramWorkflowAssignmentsResourceParams = ({ programNumber }) => {
    return {
        resourceName: "programWorkflowAssignments",
        key: programNumber,
        path: {
            programNumber,
        },
    };
};

export const getFastTagsResourceParams = ({ entityNumber, entityType }) => {
    return {
        resourceName: "fasttagsV2",
        key: entityNumber,
        query: {
            entityNumber: entityNumber,
            entityType: entityType,
        },
    };
};

export const getApplicationActivityResourceParams = ({ applicationNumber }) => {
    return {
        resourceName: "applicationActivity",
        key: "applicationActivity",
        path: {
            appId: applicationNumber,
        },
    };
};

export const getCalculationTestInputsResourceParams = ({ calculationNumber }) => {
    return {
        resourceName: "calculationTestInputs",
        key: calculationNumber,
        path: {
            calculationNumber,
        },
    };
};

export const getContractResourceParams = ({ utilityNumber, contractNumber }) => {
    return {
        resourceName: "utilitiesContracts",
        resourceId: contractNumber,
        path: {
            utilityNumber,
        },
    };
};

export const getInvoiceDocumentResourceParams = () => {
    return {
        resourceName: "invoiceDocument",
    };
};

export const getUtilitiesContractsInvoiceDocumentsFlagsResourceParams = ({ utilityNumber, contractNumber, documentNumber }) => {
    return {
        resourceName: "utilitiesContractsInvoiceDocumentsFlags",
        path: {
            utilityNumber,
            contractNumber,
            documentNumber,
        },
    };
};

export const getUtilitiesContractsInvoiceDocumentsAttributesResourceParams = ({ utilityNumber, contractNumber, documentNumber }) => {
    return {
        resourceName: "utilitiesContractsInvoiceDocumentsAttributes",
        key: documentNumber,
        path: {
            utilityNumber,
            contractNumber,
            documentNumber,
        },
    };
};

export const getApplicationAvailableDocumentsResourceParams = ({ applicationNumber, isEmail }) => {
    return {
        resourceName: "applicationAvailableDocuments",
        key: `${applicationNumber}-${isEmail ? "email" : "letter"}`,
        path: {
            appId: applicationNumber,
        },
        query: {
            itemType: isEmail ? documentType.email : documentType.letter,
        },
    };
};

export const getProgramAvailableDocumentsResourceParams = ({ programNumber }) => {
    return {
        resourceName: "programAvailableDocuments",
        key: programNumber,
        path: {
            programNumber,
        },
    };
};

export const getPowerBiReportResourceParams = ({ reportID, isPaginated, entityTypeID, entityNumber }) => {
    return {
        resourceName: "powerBI",
        key: reportID ? reportID + (entityNumber || "") : undefined,
        path: {
            reportID,
        },
        query: {
            isPaginated,
            entityTypeID,
            entityNumber,
        },
    };
};

export const getApplicationFormsResourceParams = ({ applicationNumber }) => {
    return {
        resourceName: "applicationForms",
        key: applicationNumber,
        path: {
            appId: applicationNumber,
        },
    };
};

export const getApplicationFormDetailsResourceParams = ({ applicationNumber, applicationFormId }) => {
    // Request nothing if applicationFormId is not set.
    const key = applicationFormId ? `${applicationNumber}-${applicationFormId}` : undefined;

    return {
        resourceName: "applicationFormDetails",
        resourceId: applicationFormId,
        key,
        path: {
            appId: applicationNumber,
        },
    };
};

export const getApplicationWorkflowResourceParams = ({ applicationNumber }) => {
    return {
        resourceName: "applicationWorkflow",
        key: applicationNumber,
        path: {
            appId: applicationNumber,
        },
    };
};

export const getApplicationStatusesResourceParams = ({ applicationNumber }) => {
    return {
        resourceName: "applicationWorkflowStatus",
        key: applicationNumber,
        path: {
            applicationNumber,
        },
    };
};

export const getUtilitiesContractsWorkflowStatusesResourceParams = ({ utilityNumber, contractNumber }) => {
    return {
        resourceName: "utilitiesContractsWorkflowStatuses",
        key: contractNumber,
        path: {
            utilityNumber,
            contractNumber,
        },
    };
};

export const getApplicationWorkflowStatusTransferResourceParams = ({ applicationNumber }) => {
    return {
        resourceName: "applicationWorkflowStatusTransfer",
        key: applicationNumber,
        path: {
            applicationNumber,
        },
        transform: (data) => {
            return data || [];
        },
    };
};

export const getProgramWorkflowStatusApplicationTransferResourceParams = ({ programNumber }) => {
    return {
        resourceName: "programWorkflowStatusApplicationTransfer",
        key: programNumber,
        path: {
            programNumber,
        },
        transform: (data) => {
            return data || [];
        },
    };
};

export const getProgramWorkflowStatusesResourceParams = ({ programNumber }) => {
    return {
        resourceName: "programWorkflowStatus",
        key: programNumber,
        path: {
            programNumber,
        },
        transform: (data) => {
            return data || [];
        },
    };
};

export const getInvoiceItemsResourceParams = ({ utilityNumber, contractNumber, documentNumber }) => {
    return {
        resourceName: "utilitiesContractsInvoiceDocumentsInvoices",
        key: documentNumber,
        path: {
            utilityNumber,
            contractNumber,
            documentNumber,
        },
    };
};

export const getInvoiceDocumentsResourceParams = ({ utilityNumber, contractNumber }) => {
    return {
        resourceName: "utilitiesContractsInvoiceDocuments",
        key: contractNumber,
        path: {
            utilityNumber,
            contractNumber,
        },
    };
};

export const getInvoiceNotesResourceParams = ({ utilityNumber, contractNumber, documentNumber }) => {
    return {
        resourceName: "utilitiesContractsInvoiceDocumentsNotes",
        key: documentNumber,
        path: {
            utilityNumber,
            contractNumber,
            documentNumber,
        },
    };
};

export const getCustomerResourceParams = ({ utilityNumber, customerNumber }) => {
    // Prevent API call if utilityNumber is not available
    const resourceId = utilityNumber ? customerNumber : undefined;

    return {
        resourceName: "customers",
        resourceId,
        path: {
            utilityNumber,
        },
    };
};

export const getCustomerContactsResourceParams = ({ utilityNumber, customerNumber }) => {
    return {
        resourceName: "customerContacts",
        key: customerNumber,
        path: {
            utilityNumber,
            customerNumber,
        },
    };
};

export const getCustomerContactResourceParams = ({ utilityNumber, customerNumber, contactNumber }) => {
    return {
        resourceName: "customerContacts",
        resourceId: contactNumber,
        path: {
            utilityNumber,
            customerNumber,
        },
    };
};

export const getFolderListResourceParams = ({ entityTypeId, entityId }) => {
    return {
        resourceName: "fileFolders",
        key: [entityType.invoice, entityType.calculator].includes(entityTypeId) ? undefined : entityId,
        path: {
            appId: entityId,
        },
    };
};

export const getCisCustomerDetailsResourceParams = ({
    utilityNumber,
    customerID,
    accountNumber,
    premiseNumber,
    meterID,
    searchType = cisSearchType.AccountNumber,
}) => {
    let isAdvanced = false;
    let searchList = [];

    if (customerID) {
        // customer ID may contain either customerID value or formatted criteria
        if (customerID.includes("~")) {
            // formatted criteria
            const criteria = customerID.split("|");

            criteria.forEach((i) => {
                const parameter = i.split("~");

                if (parameter.length === 2 && parameter[1] && parameter[1].trim().length !== 0) {
                    searchList.push({
                        columnName: parameter[0],
                        columnValue: parameter[1],
                    });
                }
            });
        } // customerID value
        else {
            searchList = [
                {
                    columnName: "customerid",
                    columnValue: customerID,
                },
            ];
        }
    } else if (searchType === cisSearchType.AccountNumber) {
        searchList = [
            {
                columnName: "account_number",
                columnValue: accountNumber,
            },
        ];
    } else if (searchType === cisSearchType.PremiseID) {
        searchList = [
            {
                columnName: "premiseid",
                columnValue: premiseNumber,
            },
        ];
    } else if (searchType === cisSearchType.AccountAndPremise) {
        isAdvanced = true;

        searchList = [
            {
                columnName: "account_number",
                columnValue: accountNumber,
            },
            {
                columnName: "premiseid",
                columnValue: premiseNumber,
            },
        ];
    } else if (searchType === cisSearchType.AccountAndMeter) {
        isAdvanced = true;

        searchList = [
            {
                columnName: "account_number",
                columnValue: accountNumber,
            },
            {
                columnName: "meterid",
                columnValue: meterID,
            },
        ];
    }

    return {
        resourceName: isAdvanced ? "cisCustomerDetailsAdvanced" : "cisCustomerDetails",
        key: utilityNumber + (accountNumber ?? customerID ?? premiseNumber ?? meterID),
        body: {
            utilityNumber,
            searchList,
            returnType: 2,
        },
        showSuccessNotification: false,
    };
};

export const getApplicationContactStoredCisDetailsResourceParams = ({ applicationNumber, contactNumber }) => {
    return {
        resourceName: "applicationContactsCIS",
        key: contactNumber,
        path: {
            applicationNumber,
            contactNumber,
        },
    };
};

export const getContractorsResourceParams = ({
    programNumber,
    applicationNumber,
    searchAttr = null,
    sort = null,
    pageNum = 1,
    recsPerPage = 10,
}) => {
    if (programNumber) {
        return {
            resourceName: "applicationContractorsByProgram",
            key: programNumber,
            path: {
                programNumber: programNumber,
            },
            query: {
                searchAttr,
                sort,
                pageNum,
                recsPerPage,
            },
        };
    } else if (applicationNumber) {
        return {
            resourceName: "applicationContractorsByApplication",
            key: applicationNumber,
            path: {
                applicationNumber,
            },
            query: {
                searchAttr,
                sort,
                pageNum,
                recsPerPage,
            },
        };
    }
};

export const getProgramFormFriendlyNamesResourceParams = ({ programNumber }) => {
    return {
        resourceName: "programFormFriendlyNames",
        key: programNumber,
        path: {
            programNumber,
        },
    };
};

export const getApprovedEquipmentResourceParams = ({ applicationNumber, catalogNumber }) => {
    // Prevent API call if invalid applicationNumber
    const key = isNil(applicationNumber)
        ? undefined
        : `${applicationNumber}-approved-equipment` + (catalogNumber ? `-${catalogNumber}` : "");

    return {
        resourceName: "approvedEquipment",
        key,
        path: {
            appId: applicationNumber,
        },
        query: {
            catalogNumber,
        },
    };
};

export const getApplicationFormPageDetailsResourceParams = ({ applicationNumber, formPageNumber }) => {
    return {
        resourceName: "applicationFormPageDetails",
        resourceId: formPageNumber,
        path: {
            applicationNumber,
        },
    };
};

export const getApplicationRebatesResourceParams = ({ applicationNumber, rebateNumber }) => {
    return {
        resourceName: "applicationRebates",
        resourceId: rebateNumber,
        path: {
            appId: applicationNumber,
        },
    };
};

export const getClientRoleRightsResourceParams = ({ clientRoleId }) => {
    return {
        resourceName: "clientRoleRights",
        key: clientRoleId,
        path: {
            clientRoleId,
        },
    };
};

export const getClientRoleUsersResourceParams = ({ clientRoleId, entityTypeId, entityNumber }) => {
    // Prevent API call if invalid parameters.
    const key =
        isNil(clientRoleId) || isNil(entityTypeId) || isNil(entityNumber)
            ? undefined
            : `${clientRoleId}-users-${entityTypeId}-${entityNumber}`;

    return {
        resourceName: "clientRoleUsers",
        key,
        path: {
            clientRoleId,
        },
        query: {
            entityTypeId,
            entityNumber,
        },
    };
};

export const getApplicationItemsResourceParams = ({ applicationNumber }) => {
    return {
        resourceName: "applicationItems",
        key: applicationNumber,
        path: {
            appId: applicationNumber,
        },
    };
};

export const getProgramRightsResourceParams = ({ programNumber }) => {
    return {
        resourceName: "userRights",
        key: isNil(programNumber) ? undefined : `program-${programNumber}`,
        query: {
            entityType: "3",
            entityNumber: programNumber,
        },
    };
};

export const getUtilityRightsResourceParams = ({ utilityNumber }) => {
    return {
        resourceName: "userRights",
        key: isNil(utilityNumber) ? undefined : `utility-${utilityNumber}`,
        query: {
            entityType: "2",
            entityNumber: utilityNumber,
        },
    };
};

export const getProgramFormPageFieldsResourceParams = ({ programNumber, formNumber, pageNumber }) => {
    let key = undefined;

    // Do not send request if missing some of identifiers
    if (programNumber && formNumber && pageNumber) {
        key = `${programNumber}-${formNumber}-${pageNumber}`;
    }

    return {
        resourceName: "programFormPageFields",
        key,
        path: {
            programNumber,
            formNumber,
            pageNumber,
        },
    };
};

export const getBudgetLinesResourceParams = ({ utilityNumber, contractNumber }) => {
    return {
        resourceName: "utilitiesContractsBudgetLines",
        key: contractNumber,
        path: {
            utilityNumber,
            contractNumber,
        },
        transform: (data) => {
            return sortBy(data?.budgetLineList ?? [], (row) => row.budgetName);
        },
    };
};

export const getContractAttributesResourceParams = ({ utilityNumber, contractNumber }) => {
    return {
        resourceName: "utilitiesContractsAttributes",
        key: contractNumber,
        path: {
            utilityNumber,
            contractNumber,
        },
        transform: (data) => {
            return sortBy(data?.contractAttributeList ?? [], ["itemOrder", "attributeName"]);
        },
    };
};

export const getBudgetLinesAttributesResourceParams = ({ utilityNumber, contractNumber, budgetLineNumber }) => {
    return {
        resourceName: "utilitiesContractsBudgetLinesAttributes",
        key: budgetLineNumber,
        path: {
            utilityNumber,
            contractNumber,
            budgetLineNumber,
        },
        transform: (data) => {
            return (data && data.budgetLineAttributeList) || [];
        },
    };
};

export const getBudgetLinesAmountsResourceParams = ({ utilityNumber, contractNumber, budgetLineNumber }) => {
    return {
        resourceName: "utilitiesContractsBudgetLinesAmounts",
        key: budgetLineNumber,
        path: {
            utilityNumber,
            contractNumber,
            budgetLineNumber,
        },
        transform: (data) => {
            return data?.budgetLineAmountList ?? [];
        },
    };
};

export const getBudgetLinesInvoicesResourceParams = ({ utilityNumber, contractNumber, budgetLineNumber }) => {
    return {
        resourceName: "utilitiesContractsBudgetLinesInvoices",
        key: budgetLineNumber,
        path: {
            utilityNumber,
            contractNumber,
            budgetLineNumber,
        },
        transform: (data) => {
            return (data?.invoiceList ?? []).map((i) => ({
                ...i,
                billingDate: i.billingDate?.substring(0, 10),
                dueDate: i.dueDate?.substring(0, 10),
                invoiceStartDate: i.invoiceStartDate?.substring(0, 10),
                invoiceEndDate: i.invoiceEndDate?.substring(0, 10),
            }));
        },
    };
};

export const getBudgetLookupsResourceParams = ({ utilityNumber, contractNumber }) => {
    return {
        resourceName: "utilitiesContractsBudgetLookups",
        key: contractNumber,
        path: {
            utilityNumber,
            contractNumber,
        },
        transform: (data) => {
            return data?.budgetLookupList ?? [];
        },
    };
};

export const getCalculationLookupsResourceParams = ({ utilityNumber }) => {
    return {
        resourceName: "calculationLookups",
        key: utilityNumber,
        query: {
            utilityNumber,
        },
        transform: (data) => {
            return (data?.calculationLookupsList ?? []).map((i) => ({
                lookupTableNumber: i.attrLookupTableNumber,
                lookupTableName: i.attrLookupName,
                constraints: i.attrLookup,
                dateCreated: i.dateCreated,
                dateEdited: i.dateEdited,
                userEdited: i.userEdited,
            }));
        },
    };
};

export const getClientUsersResourceParams = ({ clientNumber, utilityNumber }) => {
    const key = clientNumber && utilityNumber ? `${clientNumber}-${utilityNumber}` : undefined;

    return {
        resourceName: "clientUsers",
        key,
        path: {
            clientNumber,
        },
        query: {
            utilityNumber,
        },
    };
};

export const getPortalTemplateResourceParams = ({ utilityNumber, portalTemplateNumber, onComplete = undefined }) => {
    return {
        resourceName: "utilityPortalTemplates",
        resourceId: portalTemplateNumber,
        path: {
            utilityNumber,
        },
        onComplete,
    };
};

export const getPortalTemplateRevisionResourceParams = ({
    utilityNumber,
    portalTemplateNumber,
    portalTemplateRevisionNumber,
    onComplete = undefined,
}) => {
    return {
        resourceName: "utilityPortalTemplateRevision",
        path: {
            utilityNumber,
            portalTemplateNumber,
            portalTemplateRevisionNumber,
        },
        onComplete,
    };
};

export const getPortalProgramTemplateRevisionResourceParams = ({ programNumber, portalTemplateRevisionNumber, onComplete = undefined }) => {
    return {
        resourceName: "programPortalTemplateRevision",
        path: {
            programNumber,
            portalTemplateRevisionNumber,
        },
        onComplete,
    };
};

export const getPortalTemplatesResourceParams = ({ utilityNumber }) => {
    return {
        resourceName: "utilityPortalTemplates",
        key: utilityNumber,
        path: {
            utilityNumber,
        },
    };
};

export const getProgramPortalTemplatesResourceParams = ({ programNumber }) => {
    //fasf
    return {
        resourceName: "programPortalTemplates",
        key: programNumber,
        path: {
            programNumber,
        },
    };
};

export const getPortalConfigurationResourceParams = ({ programNumber }) => {
    return {
        resourceName: "portalConfiguration",
        key: programNumber,
        path: {
            programNumber,
        },
    };
};
