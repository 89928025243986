import { useMemo } from "react";
import { store } from "../../../store/configureStore";
import { batch } from "react-redux";
import memoizeOne from "memoize-one";
import { cloneDeep, get, isDate, isEmpty, isEqual, isNil, omit, orderBy, uniqueId } from "lodash";
import {
    fieldPropertiesUpdate,
    pagePropertiesUpdate,
    columnPropertiesUpdate,
    rowPropertiesUpdate,
    sectionPropertiesUpdate,
    removeElement,
    clearValidationError,
} from "store/formBuilder/actions";
import {
    listToAnyOf,
    referenceToAnyOf,
    getFieldNumber,
    isNonEmptyString,
    FIELD_WIDGETS,
    getWidgetDataTypeList,
    GENERAL_PROCEDURE_WIDGET_LIST,
    splitDictionaryItemValues,
    anyOfToItemValues,
    addColumnToSelectedSection,
    ElementTypes,
    addSectionToPage,
    FIELD_ID_PREFIX,
} from "../../utils/form";
import { optimisticUpdateItem } from "store/resources/actions";
import { datePartFromJsonDate, dateToJson, getDateValueForInput, jsonDateToDate } from "components/utils/date";
import { getActiveField, getActiveFieldId, getActiveUiField, getPropertyCount, isPropertiesValid } from "store/formBuilder/selectors";
import { FIELD_DESCRIPTOR_TEXT, FIELD_DESCRIPTOR_DB } from "store/formBuilder/utils";
import { isNullOrWhitespace } from "components/utils/string";
import { RunAppStatusInWizard, TrueFalse, YesNo } from "components/utils/constants";
import { toArray } from "components/utils/array";

export const fieldGroups = {
    42: "Administration",
    43: "Public",
};

export const getWidgetSchema = () => {
    const widgetList = Object.entries(FIELD_WIDGETS).map(([value, title]) => ({
        title,
        enum: [value],
    }));

    const deafaultValue = "text";

    return {
        widget: {
            type: "string",
            title: "Widget",
            anyOf: orderBy(widgetList, [(item) => item.title ?? ""]),
            default: deafaultValue,
        },
    };
};

export const getWidgetOptionsSchema = (widget) => {
    if (["date"].includes(widget)) {
        return {
            dateFormat: {
                type: "string",
                title: "Date Format",
                anyOf: [
                    {
                        title: "Date only",
                        enum: ["date"],
                    },
                    {
                        title: "Date with time",
                        enum: ["date-time"],
                    },
                    {
                        title: "Time only",
                        enum: ["time"],
                    },
                ],
                default: "date",
            },
        };
    }

    return {};
};

export const getTypeSchema = (widget) => {
    const typeList = getWidgetDataTypeList({ widget });
    const defaultValue = typeList[0] ?? "string";
    if (widget === "image") {
        return {};
    }
    return {
        type: {
            type: "string",
            title: "Field Data Type",
            enum: typeList,
            default: defaultValue,
        },
    };
};

export const getFieldGroupSchema = (widget, fieldGroups) => {
    if (widget === "image") {
        return {};
    }
    return {
        fieldGroup: {
            type: "integer",
            title: "Field Group",
            anyOf: Object.keys(fieldGroups).map((key) => ({
                title: fieldGroups[key],
                enum: [Number(key)],
            })),
        },
    };
};

export const getTypeUISchema = (widget, isLocked) => {
    const typeList = getWidgetDataTypeList({ widget });
    if (widget === "image") {
        return {};
    }
    return {
        type: {
            "ui:widget": "select",
            ...(typeList.length > 1 ? {} : { "ui:readonly": !isLocked }),
        },
    };
};

export const getFieldGroupUISchema = (widget) => {
    if (widget === "image") {
        return {};
    }
    return {
        fieldGroup: {
            "ui:placeholder": "-- SELECT --",
            "ui:widget": "select",
        },
    };
};

export const showValidationAdd = ({ elementUiParams }) => {
    return ["16", "344", "463", "577"].includes(elementUiParams["af:validationType"]);
};

export const showValidationPivot = ({ elementUiParams }) => {
    return ["16", "577"].includes(elementUiParams["af:validationType"]);
};

export const getAppFormValidationSchema = ({ elementUiParams, validationTypes, otherFieldIds, isLoadingValidationTypes, formUiSchema }) => {
    return {
        validationType: {
            type: "string",
            title: "Validation Type",
            ...(isLoadingValidationTypes
                ? {}
                : {
                      anyOf: referenceToAnyOf({
                          list: validationTypes,
                          type: "string",
                      }),
                  }),
        },
        ...(showValidationPivot({ elementUiParams })
            ? {
                  validationPivot: {
                      type: "string",
                      title: "Validation Pivot",
                      anyOf: listToAnyOf({
                          list: otherFieldIds,
                          map: (item) => ({
                              title: item.title,
                              enum: [getFieldNumber(formUiSchema, item.id)],
                          }),
                          sort: "asc",
                      }),
                  },
              }
            : {}),
        ...(showValidationAdd({ elementUiParams })
            ? {
                  validationAdd: {
                      type: "string",
                      title: "Validation Add",
                  },
              }
            : {}),
    };
};

export const getAppFormUiSchema = ({ elementUiParams, isLoadingFieldDescriptors, isLoadingValidationTypes, isLocked, fieldWidget }) => {
    const isDbValue = elementUiParams["af:fieldDescriptor"] === FIELD_DESCRIPTOR_DB;

    return {
        fieldType: {
            "ui:placeholder": "-- SELECT --",
        },
        dbString: {
            "ui:readonly": !isDbValue && !isLocked,
            "ui:widget": fieldWidget === "image" ? "hidden" : "text",
        },
        fieldNumber: {
            "ui:disabled": "true",
        },
        fieldDescriptor: {
            "ui:placeholder": isLoadingFieldDescriptors ? "Loading..." : "-- SELECT --",
            "ui:widget": fieldWidget === "image" ? "hidden" : "select",
        },
        validationType: {
            "ui:placeholder": isLoadingValidationTypes ? "Loading..." : "-- SELECT --",
            "ui:emptyItem": true,
            "ui:widget": fieldWidget === "image" ? "hidden" : "select",
        },
    };
};

export const getItemValuesSchema = (widget, uiSchema, isLoadingGenProcs, genProcs, selectedGenProc, isLoadingGenProc) => {
    if (["checkboxes", "largecheckboxes"].includes(widget) && uiSchema["af:fieldDescriptor"] !== FIELD_DESCRIPTOR_DB) {
        return {
            itemType: {
                type: "string",
                title: "Item Data Type",
                enum: ["string", "boolean", "integer", "number"],
                default: "string",
            },
            itemValues: {
                type: "string",
            },
        };
    }

    if (["select", "radio"].includes(widget) && uiSchema["af:fieldDescriptor"] !== FIELD_DESCRIPTOR_DB) {
        return {
            itemValues: {
                type: "string",
            },
        };
    }

    if (["generalprocedure"].includes(widget)) {
        const procIdSchema = isLoadingGenProcs
            ? {}
            : {
                  procDisplayType: {
                      type: "string",
                      title: "General Procedure Display Type",
                      enum: GENERAL_PROCEDURE_WIDGET_LIST,
                      default: "select",
                  },
                  procId: {
                      type: "string",
                      title: "General Procedure",
                      anyOf: listToAnyOf({
                          list: genProcs,
                          map: (item) => ({
                              title: item.display,
                              enum: [item.val],
                          }),
                      }),
                  },
              };

        if (!isLoadingGenProc && Array.isArray(selectedGenProc)) {
            return selectedGenProc
                .map((i) => i.parameterName)
                .map((name, index) => {
                    return {
                        [`parameterNamesList-${index}`]: {
                            type: "string",
                            title: `Parameter ${name}`,
                        },
                    };
                })
                .reduce((result, next) => (result = { ...result, ...next }), procIdSchema);
        }

        return procIdSchema;
    }

    return {};
};

export const getItemValuesUISchema = (widget, selectedGenProc, isLoadingGenProc) => {
    if (["checkboxes", "largecheckboxes"].includes(widget)) {
        return {
            itemType: {
                "ui:widget": "select",
            },
            itemValues: {
                "ui:displayLabel": false,
                "ui:widget": "dictionary",
            },
        };
    }

    if (["select", "radio"].includes(widget)) {
        return {
            itemValues: {
                "ui:displayLabel": false,
                "ui:widget": "dictionary",
            },
        };
    }

    if (["generalprocedure"].includes(widget)) {
        return {
            procDisplayType: {
                "ui:widget": "select",
            },
            procId: {
                "ui:widget": "select",
            },
            ...(!isLoadingGenProc && Array.isArray(selectedGenProc) && selectedGenProc[0]?.parameterName === "@fieldNumber"
                ? {
                      "parameterNamesList-0": {
                          "ui:widget": "hidden",
                      },
                  }
                : {}),
        };
    }

    return {};
};

export const getItemValuesInitialValues = (schema, uiSchema, widget, procId, selectedGenProc, isLoadingGenProc) => {
    let result = {};

    if (uiSchema["af:fieldDescriptor"] === FIELD_DESCRIPTOR_DB) {
        result = {
            ...result,
            itemValues: uiSchema["af:dbString"],
        };
    }

    if (schema.type === "array") {
        result = {
            ...result,
            itemType: schema.items ? schema.items.type : "string",
            itemValues: schema.items ? anyOfToItemValues(schema.items.anyOf) : "",
        };
    }

    if (["select", "radio"].includes(widget) && schema.type !== "array") {
        let values = "";

        if (schema.enum) {
            values = schema.enum.map((i) => `${i}=${i}`).join("\n");
        }

        if (schema.anyOf) {
            values = anyOfToItemValues(schema.anyOf);
        }

        result = {
            ...result,
            itemValues: values,
        };
    }

    if (["generalprocedure"].includes(widget)) {
        const procDisplayType = uiSchema["ui:procDisplayType"] || "select";
        const parameterNamesList = isLoadingGenProc
            ? uiSchema["ui:parameterNamesList"] || []
            : (selectedGenProc || []).map((i) => i.parameterName);
        const parameterValuesList = uiSchema["ui:parameterValuesList"] || [];

        result = {
            ...result,
            procId,
            procDisplayType,
            parameterNamesList,
            ...parameterNamesList
                .map((name, index) => ({
                    [`parameterNamesList-${index}`]: name === "@fieldNumber" ? uiSchema["af:fieldNumber"] : parameterValuesList[index],
                }))
                .reduce((props, next) => (props = { ...props, ...next }), {}),
        };
    }

    return result;
};

export const getRequiredFields = (widget, uiSchema, selectedGenProc) => {
    let requiredFields = ["title", "type", "widget", "fieldGroup"];

    if (["signature"].includes(widget)) {
        requiredFields.push("confirmCheckboxTitle");
    }

    if (["checkboxes", "largecheckboxes", "checkbox", "largecheckbox", "radio", "select"].includes(widget)) {
        requiredFields = ["title", "type", "widget", "fieldGroup", "fieldDescriptor"];
    }

    if (["checkboxes", "largecheckboxes", "radio", "select"].includes(widget)) {
        requiredFields.push("itemValues");
    }

    if (uiSchema["af:validationRequired"] && !["signature"].includes(widget)) {
        requiredFields.push("validationType");
    }

    if (["generalprocedure"].includes(widget)) {
        requiredFields.push("procDisplayType");
        requiredFields.push("procId");

        if (Array.isArray(selectedGenProc) && selectedGenProc[0]?.parameterName === "@fieldNumber") {
            requiredFields.push("parameterNamesList-1");
        }
    }

    return requiredFields;
};

export const getRelatedBuilderIds = ({ instanceId }) => {
    if (!instanceId) {
        return [];
    }

    const friendlyNamesPrefix = "friendly-names-";
    let instanceIds = [instanceId];

    if (instanceId.indexOf(friendlyNamesPrefix) === 0) {
        const relatedId = instanceId.split(friendlyNamesPrefix)[1];
        instanceIds.push(relatedId);
    } else {
        const relatedId = friendlyNamesPrefix + instanceId;
        instanceIds.push(relatedId);
    }

    return instanceIds;
};

export const onPagePropertiesChange = ({ instanceId, selectedElementId, programNumber, formNumber, formData, errorSchema }) => {
    // Convert form data to page properties
    const values = {
        ...omit(formData, ["runAppStatusInWizard"]),
        defaultPage: formData.defaultPage ? TrueFalse.True : TrueFalse.False,
        disqualificationPage: formData.disqualificationPage ? YesNo.Yes : YesNo.No,
        denyLimitedAccess: formData.denyLimitedAccess ? YesNo.Yes : YesNo.No,
        allowEditAppForm: formData.allowEditAppForm ? YesNo.Yes : YesNo.No,
        runAppEntryStatusInWizard: formData.runAppStatusInWizard === RunAppStatusInWizard.Entry ? YesNo.Yes : YesNo.No,
        runAppReceivedStatusInWizard: formData.runAppStatusInWizard === RunAppStatusInWizard.Received ? YesNo.Yes : YesNo.No,
        showFormPageOnApp: formData.showFormPageOnApp ? YesNo.Yes : YesNo.No,
    };

    getRelatedBuilderIds({ instanceId }).forEach((builderId) => {
        const formBuilder = get(store.getState(), `formBuilder[${builderId}]`) || {};

        if (formBuilder.selectedElementId !== selectedElementId) {
            return;
        }

        batch(() => {
            store.dispatch(
                pagePropertiesUpdate({
                    instanceId: builderId,
                    values,
                    programNumber,
                    formNumber,
                })
            );

            // Update field validation state
            if (isPropertiesValid(errorSchema)) {
                store.dispatch(
                    clearValidationError({
                        instanceId: builderId,
                        elementId: selectedElementId,
                    })
                );
            }
        });
    });
};

export const onSectionPropertiesChange = ({ instanceId, selectedElementId, formData, errorSchema }) => {
    getRelatedBuilderIds({ instanceId }).forEach((builderId) => {
        const formBuilder = get(store.getState(), `formBuilder[${builderId}]`) || {};

        if (formBuilder.selectedElementId !== selectedElementId) {
            return;
        }

        batch(() => {
            store.dispatch(
                sectionPropertiesUpdate({
                    instanceId: builderId,
                    values: formData,
                })
            );

            // Update field validation state
            if (isPropertiesValid(errorSchema)) {
                store.dispatch(
                    clearValidationError({
                        instanceId: builderId,
                        elementId: selectedElementId,
                    })
                );
            }
        });
    });
};

export const onColumnPropertiesChange = ({ instanceId, selectedElementId, formData, errorSchema }) => {
    getRelatedBuilderIds({ instanceId }).forEach((builderId) => {
        const formBuilder = get(store.getState(), `formBuilder[${builderId}]`) || {};

        if (formBuilder.selectedElementId !== selectedElementId) {
            return;
        }

        batch(() => {
            store.dispatch(
                columnPropertiesUpdate({
                    instanceId: builderId,
                    values: formData,
                })
            );

            // Update field validation state
            if (isPropertiesValid(errorSchema)) {
                store.dispatch(
                    clearValidationError({
                        instanceId: builderId,
                        elementId: selectedElementId,
                    })
                );
            }
        });
    });
};

export const onRowPropertiesChange = ({ instanceId, selectedElementId, formData, errorSchema }) => {
    getRelatedBuilderIds({ instanceId }).forEach((builderId) => {
        const formBuilder = get(store.getState(), `formBuilder[${builderId}]`) || {};

        if (formBuilder.selectedElementId !== selectedElementId) {
            return;
        }

        batch(() => {
            store.dispatch(
                rowPropertiesUpdate({
                    instanceId: builderId,
                    values: formData,
                })
            );

            // Update field validation state
            if (isPropertiesValid(errorSchema)) {
                store.dispatch(
                    clearValidationError({
                        instanceId: builderId,
                        elementId: selectedElementId,
                    })
                );
            }
        });
    });
};

export const onFieldPropertiesChange = ({ instanceId, selectedElementId, programNumber, formData, errorSchema }) => {
    if (isNil(formData)) {
        return;
    }
    if (formData["widget"] === "image") {
        formData["type"] = "";
    }

    getRelatedBuilderIds({ instanceId }).forEach((builderId) => {
        const formBuilder = get(store.getState(), `formBuilder[${builderId}]`) || {};

        if (formBuilder.selectedElementId !== selectedElementId) {
            return;
        }

        if (formData["friendlyName"]) {
            let value = formData["friendlyName"];
            value = value.replace(/\s/g, "");

            formData["friendlyName"] = value;
        }

        batch(() => {
            updateProgramFormFriendlyNames({
                programNumber,
                fieldNumber: formData["fieldNumber"],
                friendlyName: formData["friendlyName"],
            });

            store.dispatch(
                fieldPropertiesUpdate({
                    instanceId: builderId,
                    values: formData,
                })
            );

            // Update field validation state
            if (isPropertiesValid(errorSchema)) {
                store.dispatch(
                    clearValidationError({
                        instanceId: builderId,
                        elementId: selectedElementId,
                    })
                );
            }
        });
    });
};

export const onPagePropertiesValidate = (formData, errors) => {
    if (!isNonEmptyString(formData.title)) {
        errors.title && errors.title.addError("Invalid field value");
    }

    return errors;
};

export const onFieldPropertiesValidate = (formData, errors, friendlyNames) => {
    if (!isNonEmptyString(formData.title)) {
        errors.title && errors.title.addError("Invalid field value");
    }

    //override the default "is a required property" validation because
    //field is not validated when switching to different field and back
    if (isEmpty(formData["parameterNamesList-1"])) {
        errors["parameterNamesList-1"] && errors["parameterNamesList-1"].addError("is a required property");
    }

    if (
        ["checkboxes", "largecheckboxes", "select", "radio"].includes(formData.widget) &&
        isEmpty(formData.itemValues) &&
        formData.fieldDescriptor === FIELD_DESCRIPTOR_TEXT
    ) {
        errors.itemValues && errors.itemValues.addError("Should NOT have fewer than 1 item");
    }

    if (
        ["checkboxes", "largecheckboxes", "select", "radio"].includes(formData.widget) &&
        !isEmpty(formData.itemValues) &&
        formData.fieldDescriptor !== FIELD_DESCRIPTOR_DB
    ) {
        const itemList = splitDictionaryItemValues(formData.itemValues);

        if (itemList.some((i) => isNullOrWhitespace(i[0]) || isNullOrWhitespace(i[1]))) {
            errors.itemValues && errors.itemValues.addError("Should NOT have empty items");
        }
    }

    const { fieldNumber, friendlyName } = formData;
    if (!isUniqueFriendlyName({ fieldNumber, friendlyName, friendlyNames })) {
        errors.friendlyName && errors.friendlyName.addError("Should be unique friendly name");
    }

    const { minLength, maxLength } = formData.validation;
    if (!isNil(minLength) && !isNil(maxLength) && minLength > maxLength) {
        errors.validation.maxLength.addError("Maximum Length should not be smaller than Minimum Length");
    }

    const { minimum, maximum } = formData.validation;
    if (!isNil(minimum) && !isNil(maximum) && minimum > maximum) {
        errors.validation.maximum.addError("Maximum Value should not be smaller than Minimum Value");
    }

    const todayDate = new Date(new Date().toDateString());
    const formatMinimum = formData.validation.formatMinimum === "today" ? todayDate : jsonDateToDate(formData.validation.formatMinimum);

    const formatMaximum = formData.validation.formatMaximum === "today" ? todayDate : jsonDateToDate(formData.validation.formatMaximum);

    if (!isNil(formatMinimum) && !isNil(formatMaximum) && formatMinimum > formatMaximum) {
        errors.validation.formatMaximum.addError("Maximum Date should not be smaller than Minimum Date");
    }

    if (formData.widget === "image") {
        const imageProps = JSON.parse(formData.image);
        const { imageAltText } = imageProps;
        if (isEmpty(imageAltText)) {
            errors.image.addError("Image alt text is required");
        }
    }

    validateRules(formData, errors);

    return errors;
};

export const validateRules = (formData, errors) => {
    const { showif, removeif, requireif } = formData;

    showif.forEach((rule, index) => {
        const ruleErrors = validateRule(rule);
        if (ruleErrors.length > 0) {
            ruleErrors.forEach((e) => errors.showif[index].addError(e));
        }
    });

    removeif.forEach((rule, index) => {
        const ruleErrors = validateRule(rule);
        if (ruleErrors.length > 0) {
            ruleErrors.forEach((e) => errors.removeif[index].addError(e));
        }
    });

    requireif.forEach((rule, index) => {
        const ruleErrors = validateRule(rule);
        if (ruleErrors.length > 0) {
            ruleErrors.forEach((e) => errors.requireif[index].addError(e));
        }
    });

    function validateRule(rule) {
        const errors = [];
        const requiredMessage = "is a required property";
        let fieldMessage = undefined;
        let conditionMessage = undefined;
        let valueMessage = undefined;

        if (rule) {
            const { condition, value } = JSON.parse(rule);

            if (
                (!value && !isEmpty(condition) && !["empty", "notEmpty"].includes(condition)) ||
                (["anyOf", "notAnyOf"].includes(condition) && (toArray(value).some((i) => !i) || toArray(value).length === 0)) ||
                (["between"].includes(condition) && (toArray(value).length < 2 || toArray(value).some((i) => !i)))
            ) {
                valueMessage = requiredMessage;
            }

            if (!valueMessage && condition === "between" && toArray(value).length === 2 && value.every((v) => !isNil(v))) {
                const errorMessage = "The Minimum Value must be smaller than the Maximum Value";
                let minDateValue = getDateValueForInput({ value: value[0], isFormatDate: true });
                let maxDateValue = getDateValueForInput({ value: value[1], isFormatDate: true });
                if (isDate(minDateValue) || isDate(maxDateValue)) {
                    minDateValue = datePartFromJsonDate(dateToJson(value[0] === "today" ? new Date() : minDateValue));
                    maxDateValue = datePartFromJsonDate(dateToJson(value[1] === "today" ? new Date() : maxDateValue));
                    if (minDateValue >= maxDateValue) {
                        valueMessage = errorMessage;
                    }
                } else if (value[0] >= value[1]) {
                    valueMessage = errorMessage;
                }
            }
        }

        if (fieldMessage || conditionMessage || valueMessage) {
            errors.push(
                JSON.stringify({
                    field: fieldMessage,
                    condition: conditionMessage,
                    value: valueMessage,
                })
            );
        }

        return errors;
    }

    return errors;
};

export const transformFieldPropertiesErrors = (errors) => {
    return errors.filter((error) => error.message !== "should be string");
};

export const onRemoveElement = ({ instanceId, elementId, fieldNumber, programNumber }) => {
    batch(() => {
        if (programNumber && fieldNumber) {
            updateProgramFormFriendlyNames({
                programNumber,
                fieldNumber,
                friendlyName: undefined,
            });
        }

        store.dispatch(removeElement({ instanceId, elementId }));
    });
};

export const isUniqueFriendlyName = memoizeOne(({ fieldNumber, friendlyName, friendlyNames }) => {
    if (isNil(fieldNumber) || isNil(friendlyName)) {
        return true;
    }

    const lowerCaseFriendlyName = friendlyName.toLowerCase();
    const existingFields = friendlyNames.filter((item) => item.friendlyName.toLowerCase() === lowerCaseFriendlyName);

    if (existingFields.length === 0) {
        return true;
    }

    if (existingFields.length > 1) {
        return false;
    }

    if (existingFields[0].fieldNumber === fieldNumber) {
        return true;
    }

    return false;
}, isEqual);

/**
 * Returns the UI schema for conditions.
 * @param {Array} otherFieldIds - An array of field IDs.
 * @param {Array} otherFieldIds.id - The field ID.
 * @param {Array} otherFieldIds.title - The field title.
 * @param {Array} otherFieldIds.type - The field type.
 * @param {Array} [otherFieldIds.format] - The field format.
 * @returns {Object} The UI schema for conditions.
 */
export const getConditionsUiSchema = (otherFields) => {
    return {
        classNames: "conditional-validation",
        "ui:options": {
            orderable: false,
        },
        items: {
            "ui:widget": "ConditionalValidationRuleWidget",
            "ui:options": {
                otherFields,
            },
        },
    };
};

export const useCurrentPosition = ({ selectedElementId, elementUiParams }) => {
    const order = useMemo(() => {
        return (elementUiParams && elementUiParams["order"]) || [];
    }, [elementUiParams]);

    return useMemo(() => {
        const elementRelativeId = selectedElementId.split("_").pop();
        let position = order.indexOf(elementRelativeId);
        if (position === -1) {
            position = 0;
        }

        return position;
    }, [order, selectedElementId]);
};

export const updateProgramFormFriendlyNames = ({ programNumber, fieldNumber, friendlyName = "" }) => {
    const friendlyNames = store.getState().resources.programFormFriendlyNames.itemsById[programNumber]?.fieldFriendlyNameList ?? [];
    const existingField = friendlyNames.find((i) => i.fieldNumber === fieldNumber);

    const updateValue = (value) => {
        store.dispatch(
            optimisticUpdateItem({
                resourceName: "programFormFriendlyNames",
                resourceId: programNumber,
                value: {
                    fieldFriendlyNameList: value,
                },
            })
        );
    };

    if (existingField) {
        // Update existing friendlyName if changed
        if (existingField.friendlyName.toLowerCase() !== friendlyName.toLowerCase()) {
            if (friendlyName.length === 0) {
                // Remove friendlyName
                updateValue(friendlyNames.filter((i) => i.fieldNumber !== fieldNumber));
            } else {
                // Update friendlyName
                updateValue(
                    friendlyNames.map((i) => ({
                        ...i,
                        friendlyName: i.fieldNumber === fieldNumber ? friendlyName : i.friendlyName,
                    }))
                );
            }
        }
    } else {
        if (friendlyName.length > 0) {
            // Add friendlyName
            updateValue(
                friendlyNames.concat([
                    {
                        fieldNumber,
                        friendlyName,
                    },
                ])
            );
        }
    }
};

export const copyFieldToPage = ({ formBuilder, targetPage, targetSectionKey, targetColumnKey }) => {
    const sourceFieldId = getActiveFieldId(formBuilder);
    const sourceFieldSchema = getActiveField(formBuilder);
    const sourceFieldUiSchema = getActiveUiField(formBuilder);
    const isRequired = sourceFieldUiSchema["af:validationRequired"];

    let updatedPage = cloneDeep(targetPage);

    let targetSection = targetSectionKey;
    let targetColumn = targetColumnKey;

    // Add section if not exists
    if (!targetSection) {
        const builder = {
            schema: updatedPage.configuration.schema,
            uiSchema: updatedPage.configuration.uiSchema,
            selectedElementId: `root`,
        };

        const { schema, uiSchema } = addSectionToPage(builder);
        updatedPage.configuration.schema = schema;
        updatedPage.configuration.uiSchema = uiSchema;
        targetSection = Object.keys(updatedPage.configuration.schema.properties)[0];
    }

    // Add column if not exists
    if (!targetColumn) {
        const builder = {
            schema: updatedPage.configuration.schema,
            uiSchema: updatedPage.configuration.uiSchema,
            selectedElementId: `root_${targetSection}`,
        };

        const { schema, uiSchema } = addColumnToSelectedSection(builder);
        updatedPage.configuration.schema = schema;
        updatedPage.configuration.uiSchema = uiSchema;
        targetColumn = Object.keys(updatedPage.configuration.schema.properties[targetSection].properties)[0];
    }

    // Ensure we are using unique field id in the target form
    let targetFieldId = sourceFieldId;
    while (updatedPage.configuration.schema.properties[targetSection].properties[targetColumn].properties[targetFieldId]) {
        targetFieldId = uniqueId(FIELD_ID_PREFIX);
    }

    // update required
    if (isRequired) {
        updatedPage.configuration.schema.properties[targetSection].properties[targetColumn].required =
            updatedPage.configuration.schema.properties[targetSection].properties[targetColumn].required.concat(targetFieldId);
    }

    // update schema
    updatedPage.configuration.schema.properties[targetSection].properties[targetColumn].properties = {
        ...updatedPage.configuration.schema.properties[targetSection].properties[targetColumn].properties,
        [targetFieldId]: {
            ...sourceFieldSchema,
        },
    };

    // Ensure ui:order prop exists
    if (!updatedPage.configuration.uiSchema[targetSection][targetColumn]["ui:order"]) {
        updatedPage.configuration.uiSchema[targetSection][targetColumn]["ui:order"] = [];
    }

    // update uiSchema
    updatedPage.configuration.uiSchema[targetSection][targetColumn] = {
        ...updatedPage.configuration.uiSchema[targetSection][targetColumn],
        "ui:order": updatedPage.configuration.uiSchema[targetSection][targetColumn]["ui:order"].concat(targetFieldId),
        [targetFieldId]: {
            ...sourceFieldUiSchema,
        },
    };

    return updatedPage;
};

export const canDeleteElement = ({ isSelected, existingElementNumbers, elementNumber, elementType, schema }) => {
    if (!isSelected) {
        return false;
    }

    if (elementType === ElementTypes.COLUMN) {
        return getPropertyCount(schema) === 0;
    }

    return !existingElementNumbers.includes(elementNumber);
};
