import React, { memo, useMemo } from "react";
import { Droppable } from "react-beautiful-dnd";

import { useReference } from "../Reference/useReference";
import { referenceTypes } from "../Reference/referenceTypes";
import { referenceToDropDownList } from "../../utils/form";
import { useProgramAttributes } from "../../../store/resources/useResource";
import { getAvailableAttributesList } from "./utils";

import WaitIcon from "../WaitIcon";
import AttributesFormItem from "./AttributesFormItem";
import IconWithLabel from "../Icons/IconWithLabel";
import { isLockedText } from "components/views/ProgramView/utils";

const AttributesFormList = memo(
    ({
        programNumber,
        attributeFormRef,
        attributesKey,
        availableAttributesResourceName,
        availableAttributesResourceKeys,
        isLocked,
        isSubmitting,
        attributeMaxLength,
        attributes,
        onRemoveAttribute,
        onSelectCalculation,
        scrollRef,
        onAddAttribute,
        isAttributeErrorFullList,
    }) => {
        const numberKey = availableAttributesResourceKeys.number;
        const descriptionKey = availableAttributesResourceKeys.description;

        const [validationTypes = [], isLoadingValidationTypes] = useReference(referenceTypes.equipmentValidation);
        const validationTypesList = useMemo(() => {
            return referenceToDropDownList({ list: validationTypes });
        }, [validationTypes]);

        const [availableAttributes = [], isLoadingAttributes] = useProgramAttributes({
            programNumber,
            resourceName: availableAttributesResourceName,
        });
        const availableAttributesList = useMemo(() => {
            return getAvailableAttributesList({
                attributes,
                availableAttributes,
                numberKey,
                descriptionKey,
            });
        }, [attributes, availableAttributes, numberKey, descriptionKey]);

        const isAttributeError = attributes.some((a) => a._error);

        if (isLoadingAttributes || isLoadingValidationTypes) {
            return <WaitIcon className="align-self-center" withSpaceAround />;
        }

        return (
            <Droppable droppableId="droppable" direction="vertical">
                {(provided) => (
                    <div
                        className="attributes-form-items--scroll-container flex-column with-scroll"
                        ref={(tableRef) => {
                            scrollRef.current = tableRef;
                            provided.innerRef(tableRef);
                        }}
                        {...provided.droppableProps}
                    >
                        {attributes.map((item, index) => (
                            <AttributesFormItem
                                key={item._id}
                                itemId={item._id}
                                index={index}
                                programNumber={programNumber}
                                attributeFormRef={attributeFormRef}
                                attributesKey={attributesKey}
                                isLocked={isLocked}
                                isSubmitting={isSubmitting}
                                isValidationLocked={isAttributeError && !item._error}
                                attributeMaxLength={attributeMaxLength}
                                validationTypes={validationTypesList}
                                // Provide list only to new attributes to not rerender existing ones on list change
                                attributes={item._existing ? null : availableAttributesList}
                                availableAttributesResourceName={availableAttributesResourceName}
                                availableAttributesResourceKeys={availableAttributesResourceKeys}
                                onRemove={onRemoveAttribute}
                                onSelectCalculation={onSelectCalculation}
                            />
                        ))}
                        {provided.placeholder}

                        <div className="add-attribute-button-row">
                            <div className="add-attribute-button-inner">
                                <IconWithLabel
                                    icon="plus"
                                    onClick={onAddAttribute}
                                    disabled={isLocked || isAttributeErrorFullList}
                                    tooltip={isLocked ? isLockedText : undefined}
                                >
                                    Add Attribute
                                </IconWithLabel>
                            </div>
                        </div>
                    </div>
                )}
            </Droppable>
        );
    }
);

export default AttributesFormList;
